var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: _vm.deletingToken, "max-width": "500" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on: dialog }) {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on: tooltip }) {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  color: "secondary",
                                  small: "",
                                  icon: "",
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    { attrs: { dark: "", small: "" } },
                                    { ...tooltip, ...dialog }
                                  ),
                                  [_vm._v("delete")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" Delete ")]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", [
            _c(
              "div",
              { staticClass: "secondary--text" },
              [
                _c("v-icon", { staticClass: "mr-1" }, [_vm._v("delete")]),
                _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
              ],
              1
            ),
          ]),
          _c("v-divider", { staticClass: "mb-1" }),
          _c("v-card-text", [
            _c("div", { staticClass: "font-weight-bold mb-5" }, [
              _vm._v("Are you sure you want to delete the following token?"),
            ]),
            _c("div", { staticClass: "subtitle-2" }, [
              _vm._v(_vm._s(_vm.token)),
            ]),
          ]),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.deletingToken,
                    color: "secondary",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("cancel")]
              ),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.deletingToken,
                    color: "error",
                    text: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteToken()
                    },
                  },
                },
                [_vm._v("delete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }